import React from 'react';

function Avila() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="18"
      fill="none"
      viewBox="0 0 31 18"
      className="ml-1 inline-block"
    >
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M22.137 2.958a1.589 1.589 0 00-.567.26c-.397.263-.36.198-1.802 3.114a231.915 231.915 0 01-1.311 2.628c-.009.006-.417-.804-.907-1.798-.895-1.815-1.007-2.028-1.161-2.197-.716-.785-2.107-.699-2.674.167-.053.08-.585 1.137-1.183 2.349l-1.088 2.203-.757-1.533C9.81 6.374 9.7 6.207 9.275 6.001l-.1-.047v-.751c0-.66-.004-.754-.041-.768a2.62 2.62 0 00-.375-.016h-.332V5.8H8.31c-.451 0-1.042.35-1.31.777-.087.14-4.54 9.162-4.54 9.2 0 .017 4.667.026 13.737.026 9.123 0 13.737-.009 13.737-.027 0-.046-5.902-11.991-5.99-12.123-.365-.549-1.148-.85-1.808-.696z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Avila;
