import React from 'react';
import Link from 'next/link';
import LinkedIn from './icons/Linkedin';
import Facebook from './icons/Facebook';
import Instagram from './icons/Instagram';
import Twitter from './icons/Twitter';
import YouTube from './icons/Youtube';
import Avila from './icons/Avila';
import i18n from '../lib/i18n';
import EnCommon from '../public/static/locales/en/common.json';
import EsCommon from '../public/static/locales/es/common.json';

export default function Footer() {
  const date = new Date();
  return (
    <footer className="bg-primary-500 w-full h-auto">
      <div className="flex flex-col items-center justify-center   text-secondary-100">
        <div className="flex lg:flex-row md:flex-row xs:flex-col justify-center items-center mt-10 md:text-base sm:text-sm xs:text-xs">
          <div className="xs:mt-1 md:mx-2">
            <Link href="/sell">
              <a className="font-bold hover:text-gray-500">
                {' '}
                {i18n?.i18n?.language === 'en'
                  ? EnCommon?.navbar?.sell
                  : EsCommon?.navbar?.sell}
              </a>
            </Link>
          </div>
          <div className="xs:mt-1 md:mx-2">
            <Link href="/buy">
              <a className="font-bold hover:text-gray-500">
                {' '}
                {i18n?.i18n?.language === 'en'
                  ? EnCommon?.navbar?.buy
                  : EsCommon?.navbar?.buy}
              </a>
            </Link>
          </div>
          <div className="xs:mt-1 md:mx-2">
            <Link href="/search">
              <a className="font-bold hover:text-gray-500">
                {' '}
                {i18n?.i18n?.language === 'en'
                  ? EnCommon?.navbar?.rent
                  : EsCommon?.navbar?.rent}
              </a>
            </Link>
          </div>
          <div className="xs:mt-1 md:mx-2">
            <Link href="/investment">
              <a className="font-bold hover:text-gray-500">
                {' '}
                {i18n?.i18n?.language === 'en'
                  ? EnCommon?.navbar?.invest
                  : EsCommon?.navbar?.invest}
              </a>
            </Link>
          </div>
          <div className="xs:mt-1 md:mx-2">
            <Link href="/us#inventory">
              <a className="font-bold hover:text-gray-500">
                {' '}
                {i18n?.i18n?.language === 'en'
                  ? EnCommon?.navbar?.inventory
                  : EsCommon?.navbar?.inventory}
              </a>
            </Link>
          </div>
          <div className="xs:mt-1 md:mx-2">
            <Link href="/#services">
              <a className="font-bold hover:text-gray-500">
                {' '}
                {i18n?.i18n?.language === 'en'
                  ? EnCommon?.navbar?.services
                  : EsCommon?.navbar?.services}
              </a>
            </Link>
          </div>
          <div className="xs:mt-1 md:mx-2">
            <Link href="/about">
              <a className="font-bold hover:text-gray-500">
                {' '}
                {i18n?.i18n?.language === 'en'
                  ? EnCommon?.navbar?.aboutUs
                  : EsCommon?.navbar?.aboutUs}
              </a>
            </Link>
          </div>
          <div className="xs:mt-1 md:mx-2">
            <Link href="/#contact">
              <a className="font-bold hover:text-gray-500">
                {' '}
                {i18n?.i18n?.language === 'en'
                  ? EnCommon?.navbar?.contact
                  : EsCommon?.navbar?.contact}
              </a>
            </Link>
          </div>
          <div className="xs:mt-1 md:mx-2">
            <Link href="/us">
              <a className="font-bold hover:text-gray-500">Usa</a>
            </Link>
          </div>
          <div className="xs:mt-1 md:mx-2">
            <Link href="/spain">
              <a className="font-bold hover:text-gray-500">Spain</a>
            </Link>
          </div>
        </div>
        <div className="flex justify-center mt-6">
          <div className="mx-2">
            <a
              href="https://linkedin.com/company/grupo-mre/about/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedIn />
            </a>
          </div>
          <div className="mx-2">
            <a
              href="https://facebook.com/MerandRealEstate"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook />
            </a>
          </div>
          <div className="mx-2">
            <a
              href="https://instagram.com/grupomre/"
              target="_blank"
              rel="noreferrer"
            >
              <Instagram />
            </a>
          </div>
          <div className="mx-2">
            <a
              href="https://twitter.com/GrupoMRE"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter />
            </a>
          </div>
          <div className="mx-2">
            <a
              href="https://youtube.com/channel/UCj1GOp1JCfAaSmBdQn5uU9w"
              target="_blank"
              rel="noreferrer"
            >
              <YouTube />
            </a>
          </div>
        </div>
        <div className="flex md:flex-row xs:flex-col  items-center justify-center mt-6 md:text-base sm:text-sm xs:text-xs mb-3">
          <p>
            &copy; {date?.getFullYear()}. All rights reserved. Developed by
            <a href="https://avilatek.dev" target="_blank" rel="noreferrer">
              Avila Tek
              <Avila />{' '}
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
