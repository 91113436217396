import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Hamburguer from './icons/Hamburguer';
import i18n from '../lib/i18n';
import EnCommon from '../public/static/locales/en/common.json';
import EsCommon from '../public/static/locales/es/common.json';

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [language, setLanguage] = React.useState<string>(
    i18n?.i18n?.language ?? ''
  );
  const router = useRouter();
  const languagesOptions = ['en', 'es'];
  const pathName = router?.asPath;
  const isRent = router?.query.rent === 'true';

  return (
    <>
      <nav className="relative select-none bg-grey lg:flex lg:items-stretch w-full xs:min-h-full lg:h-28">
        <div className="flex flex-no-shrink items-stretch justify-between">
          <Link href="/">
            <img
              className="object-contain xs:h-12 md:h-16 xs:mt-1 lg:mt-5 lg:py-0 w-32 xs:block lg:hidden xs:my-3 lg:my-1 xs:py-1"
              src="/img/main-logo-grey.png"
              alt="logo"
            />
          </Link>
          <button
            className="text-secondary-500 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none mt-1"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <Hamburguer />
          </button>
        </div>
        <div
          className={`lg:flex lg:items-stretch lg:flex-no-shrink lg:flex-grow ${
            navbarOpen ? 'flex' : 'hidden'
          }`}
        >
          <div className="lg:flex lg:items-stretch lg:justify-end xs:ml-5 lg:mx-auto">
            <Link href="/">
              <a className="mx-1 flex items-center text-sm uppercase font-semibold leading-snug hover:opacity-75 xs:mb-2 lg:mb-0 xs:mt-2 lg:mt-0">
                <span
                  className={`ml-2 ${
                    pathName === '/' ? 'text-primary-500' : 'text-secondary-500'
                  }`}
                >
                  {i18n?.i18n?.language === 'en'
                    ? EnCommon?.navbar?.home
                    : EsCommon?.navbar?.home}
                </span>
              </a>
            </Link>
            <Link href="/sell">
              <a className="mx-1 flex items-center text-sm uppercase font-semibold leading-snug hover:opacity-75 xs:mb-2 lg:mb-0">
                <span
                  className={`ml-2 ${
                    pathName === '/sell'
                      ? 'text-primary-500'
                      : 'text-secondary-500'
                  }`}
                >
                  {i18n?.i18n?.language === 'en'
                    ? EnCommon?.navbar?.sell
                    : EsCommon?.navbar?.sell}
                </span>
              </a>
            </Link>
            <Link href="/buy">
              <a className="mx-1 flex items-center text-sm uppercase font-semibold leading-snug text-secondary-500 hover:opacity-75 xs:mb-2 lg:mb-0">
                <span
                  className={`ml-2 ${
                    pathName === '/buy'
                      ? 'text-primary-500'
                      : 'text-secondary-500'
                  }`}
                >
                  {i18n?.i18n?.language === 'en'
                    ? EnCommon?.navbar?.buy
                    : EsCommon?.navbar?.buy}
                </span>
              </a>
            </Link>
            <Link href="/investment">
              <a className="mx-1 flex items-center text-sm uppercase font-semibold leading-snug hover:opacity-75 xs:mb-2 lg:mb-0">
                <span
                  className={`ml-2 ${
                    pathName === '/investment'
                      ? 'text-primary-500'
                      : 'text-secondary-500'
                  }`}
                >
                  {i18n?.i18n?.language === 'en'
                    ? EnCommon?.navbar?.invest
                    : EsCommon?.navbar?.invest}
                </span>
              </a>
            </Link>

            <Link href="/us#inventory">
              <a className="mx-1 flex items-center text-sm uppercase font-semibold leading-snug hover:opacity-75 xs:mb-2 lg:mb-0">
                <span
                  className={`ml-2 ${
                    pathName === '/us#inventory'
                      ? 'text-primary-500'
                      : 'text-secondary-500'
                  }`}
                >
                  {i18n?.i18n?.language === 'en'
                    ? EnCommon?.navbar?.inventory
                    : EsCommon?.navbar?.inventory}
                </span>
              </a>
            </Link>
            <Link
              href={{
                pathname: '/search/',
                query: { rent: 'true' },
              }}
            >
              <a className="mx-1 flex items-center text-sm uppercase font-semibold leading-snug text-secondary-500 hover:opacity-75 xs:mb-2 lg:mb-0">
                <span
                  className={`ml-2 ${
                    pathName === '/search' || isRent
                      ? 'text-primary-500'
                      : 'text-secondary-500'
                  }`}
                >
                  {i18n?.i18n?.language === 'en'
                    ? EnCommon?.navbar?.rent
                    : EsCommon?.navbar?.rent}
                </span>
              </a>
            </Link>
            <Link href="/">
              <img
                className="object-contain sm:h-12 md:h-16 w-32 m-auto xs:hidden lg:block"
                src="/img/main-logo-grey.png"
                alt="logo"
              />
            </Link>
            <Link href="/#services">
              <a className="mx-1 flex items-center text-sm uppercase font-semibold leading-snug hover:opacity-75 xs:mb-2 lg:mb-0">
                <span
                  className={` xs:ml-2 lg:ml-0 ${
                    pathName === '/#services'
                      ? 'text-primary-500'
                      : 'text-secondary-500'
                  }`}
                >
                  {i18n?.i18n?.language === 'en'
                    ? EnCommon?.navbar?.services
                    : EsCommon?.navbar?.services}
                </span>
              </a>
            </Link>
            <Link href="/about">
              <a className="mx-1 flex items-center text-sm uppercase font-semibold leading-snug hover:opacity-75 xs:mb-2 lg:mb-0">
                <span
                  className={`ml-2 ${
                    pathName === '/#about'
                      ? 'text-primary-500'
                      : 'text-secondary-500'
                  }`}
                >
                  {i18n?.i18n?.language === 'en'
                    ? EnCommon?.navbar?.aboutUs
                    : EsCommon?.navbar?.aboutUs}
                </span>
              </a>
            </Link>
            <Link href="/#contact">
              <a className="mx-1 flex items-center text-sm uppercase font-semibold leading-snug hover:opacity-75 xs:mb-2 lg:mb-0">
                <span
                  className={`ml-2 ${
                    pathName === '/#contact'
                      ? 'text-primary-500'
                      : 'text-secondary-500'
                  }`}
                >
                  {i18n?.i18n?.language === 'en'
                    ? EnCommon?.navbar?.contact
                    : EsCommon?.navbar?.contact}
                </span>
              </a>
            </Link>
            <Link href="/us">
              <a className="mx-1 flex items-center text-sm uppercase font-semibold leading-snug hover:opacity-75 xs:mb-2 lg:mb-0">
                <span
                  className={`ml-2 ${
                    pathName === '/us'
                      ? 'text-primary-500'
                      : 'text-secondary-500'
                  }`}
                >
                  {i18n?.i18n?.language === 'en'
                    ? EnCommon?.navbar?.us
                    : EsCommon?.navbar?.us}
                </span>
              </a>
            </Link>
            <Link href="/spain">
              <a className="mx-1 flex items-center text-sm uppercase font-semibold leading-snug hover:opacity-75 xs:mb-2 lg:mb-0">
                <span
                  className={`ml-2 ${
                    pathName === '/spain'
                      ? 'text-primary-500'
                      : 'text-secondary-500'
                  }`}
                >
                  {i18n?.i18n?.language === 'en'
                    ? EnCommon?.navbar?.spain
                    : EsCommon?.navbar?.spain}
                </span>
              </a>
            </Link>
            <select
              className="text-secondary-500 font-semibold bg-none bg-transparent text-sm lg:ml-0 py-2 border-0 uppercase w-24"
              onChange={(e) => {
                setLanguage(e?.target?.value);
                i18n.i18n.changeLanguage(e?.target?.value);
              }}
              id="selectLanguage"
              value={language}
              defaultValue={language}
            >
              <option value="none" disabled>
                {i18n?.i18n?.language === 'en'
                  ? 'Select a language'
                  : 'Selecciona un idioma'}
              </option>
              {languagesOptions.map((_languages) => (
                <option value={_languages} className="uppercase">
                  {_languages}
                </option>
              ))}
            </select>
          </div>
        </div>
      </nav>
    </>
  );
}
